import {
  SectionContent,
  SectionContentTitle,
  GameSection,
  GameLeftSection,
  GameRightSection,
  GameTitle,
  GameSubHeader,
  GameDescItch,
  GameDescription,
  GameItchCard,
  GameVideo,
  GameScreenshotsSection,
  GameScreenshot,
  // GameProgressLabel,
  // GameProgressSection,
  // GameProgressDecription,
  // GameProgressImage,
} from "../StyledApp";
import Footer from "./Footer";

function Games({ selected, openLightbox }) {
  const gamesList = [
    {
      title: "Bomb Knight",
      subHeader: "Explosive fantasy adventure",
      description:
        "Set in a fantasy world every jump either releases the bomb head or explodes the currently active one exploding BombKnight into the air! Use this to your advantage along with several other unlockable abailities to meet all the characters and ring the tower bell.\n \nBuilt in Unity using art and animations made in Aseprite.\n \nMost Commercially Viable - Won\nBest 2D Art - Won\nMost Fun - Nominated\nBest Game Design - Nominated\nBest Solo Developer - Nominated\nBest Use of Theme - Nominated\nCommunity Vote (Overall) - 6th\n \nArt/Music/Programming - Talluff\nSoundFX - DungeonMusicPack",
      videoLink: "https://www.youtube.com/embed/1QdLzA5LNgw",
      itchIframe: (
        <iframe
          frameBorder="0"
          src="https://itch.io/embed/1286524?border_width=0&amp;bg_color=282828"
          width="206"
          height="165"
          title="bombKnightItch"
        >
          <a href="https://talluff.itch.io/bombknight">
            Bomb Knight by Talluff
          </a>
        </iframe>
      ),
      gameImages: [
        {
          src: "https://img.itch.zone/aW1hZ2UvMTI4NjUyNC83NTU5ODY2LnBuZw==/original/1F5dH4.png",
          alt: "hi",
        },
        {
          src: "https://img.itch.zone/aW1hZ2UvMTI4NjUyNC83NTU5ODY4LnBuZw==/original/mplYGF.png",
          alt: "hi",
        },
        {
          src: "https://img.itch.zone/aW1hZ2UvMTI4NjUyNC83NTU5ODY5LnBuZw==/original/%2Fm%2BidS.png",
          alt: "hi",
        },
        {
          src: "https://img.itch.zone/aW1hZ2UvMTI4NjUyNC83NTU5ODcyLnBuZw==/original/oMVyuy.png",
          alt: "hey",
        },
      ],
      devDiary:
        "Quis irure sint qui excepteur. Aliquip aute eiusmod dolor amet nulla eu non exercitation occaecat mollit proident. Incididunt aliquip labore et consequat ad elit anim laborum. Voluptate minim ullamco duis occaecat ex elit eiusmod sint ullamco voluptate Lorem.",
      processImages: [
        { src: "", alt: "", description: "" },
        { src: "", alt: "", description: "" },
      ],
    },
    {
      title: "Energy Gain",
      subHeader: "Rapid single-use action",
      description:
        "Top down action with single use abilities in this fast paced dungeon game. By utilizing all the available powers and avoiding the enemy attacks you must clear each room without wasting an oppurtunity.\n \nBuilt in Unity using art and animations made in Aseprite and Music from Garageband\n \nBest Solo Game - Won\nMost fun - Nominated\nTechnical Accomplishment - Nominated\nCommunity Vote (Overall) - 16th\n \nArt/Music/Programming - Talluff\nSoundFX - MMO Game Magic",
      videoLink: "https://www.youtube.com/embed/vpWqiLjZDbE",
      itchIframe: (
        <iframe
          frameBorder="0"
          src="https://itch.io/embed/1129656?border_width=0&amp;bg_color=282828"
          width="206"
          height="165"
          title="energyGainItch"
        >
          <a href="https://talluff.itch.io/energy-gain">
            Energy Gain by Talluff
          </a>
        </iframe>
      ),
      gameImages: [
        {
          src: "https://img.itch.zone/aW1hZ2UvMTEyOTY1Ni82NTY3MDkzLnBuZw==/original/BHV7KF.png",
          alt: "hi",
        },
        {
          src: "https://img.itch.zone/aW1hZ2UvMTEyOTY1Ni82NTY3MDk2LnBuZw==/original/nqWkt6.png",
          alt: "hi",
        },
        {
          src: "https://img.itch.zone/aW1hZ2UvMTEyOTY1Ni82NTY3MDk0LnBuZw==/original/MbfdFq.png",
          alt: "hi",
        },
        {
          src: "https://img.itch.zone/aW1hZ2UvMTEyOTY1Ni82NTY3MDk3LnBuZw==/original/5rjtwS.png",
          alt: "hey",
        },
      ],
      devDiary:
        "Quis irure sint qui excepteur. Aliquip aute eiusmod dolor amet nulla eu non exercitation occaecat mollit proident. Incididunt aliquip labore et consequat ad elit anim laborum. Voluptate minim ullamco duis occaecat ex elit eiusmod sint ullamco voluptate Lorem.",
      processImages: [
        { src: "", alt: "", description: "" },
        { src: "", alt: "", description: "" },
      ],
    },
    {
      title: "Slime-Sync",
      subHeader: "Slimey puzzle platformer",
      description:
        "Pixel puzzle platformer controlling multiple slimes. One slime has split into three separate blobs, they still share the same brain but slightly different attributes. Control them together to reach the slime haven!\n \nBuilt in Unity using art and animations made in Aseprite and Audio from Garageband\n \nBest Solo Game - Won\nBest Level Design - Nominated\nBest Game Design - Nominated\nCommunity Vote (Overall) - 2nd\n \nArt/Audio/Programming - Talluff",
      videoLink: "https://www.youtube.com/embed/OunT8ui-8oE",
      itchIframe: (
        <iframe
          frameBorder="0"
          src="https://itch.io/embed/939777?border_width=0&amp;bg_color=282828"
          width="206"
          height="165"
          title="slimeSyncItch"
        >
          <a href="https://talluff.itch.io/slime-sync">Slime Sync by Talluff</a>
        </iframe>
      ),
      gameImages: [
        {
          src: "https://img.itch.zone/aW1hZ2UvOTM5Nzc3LzIwNjIxMzExLnBuZw==/original/ifyets.png",
          alt: "hi",
        },
        {
          src: "https://img.itch.zone/aW1hZ2UvOTM5Nzc3LzIwNjIxMzEzLnBuZw==/original/m1Ja6%2B.png",
          alt: "hi",
        },
        {
          src: "https://img.itch.zone/aW1hZ2UvOTM5Nzc3LzIwNjIxMzE1LnBuZw==/original/iTEEWK.png",
          alt: "hi",
        },
        {
          src: "https://img.itch.zone/aW1hZ2UvOTM5Nzc3LzIwNjIxMzE4LnBuZw==/original/qAF0Fg.png",
          alt: "hey",
        },
      ],
      devDiary:
        "Quis irure sint qui excepteur. Aliquip aute eiusmod dolor amet nulla eu non exercitation occaecat mollit proident. Incididunt aliquip labore et consequat ad elit anim laborum. Voluptate minim ullamco duis occaecat ex elit eiusmod sint ullamco voluptate Lorem.",
      processImages: [
        { src: "", alt: "", description: "" },
        { src: "", alt: "", description: "" },
      ],
    },
    {
      title: "Upward",
      subHeader: "Speedy climb to the top",
      description:
        "Fast paced pixel platformer to reach the top of the tower and escape the death trap approaching from below. Go on a short quest to the top of the tower to seize the sun!\n \nBuilt in Unity using art and animations made in Aseprite and Audio from BOSCA CEOIL\n \nCommunity Vote (Overall) - 17th\n \nArt/Programming - Talluff\nMusic - Universal Production Music",
      videoLink: "https://www.youtube.com/embed/KK6m1XT4JOg",
      itchIframe: (
        <iframe
          frameBorder="0"
          src="https://itch.io/embed/792961?border_width=0&amp;bg_color=282828"
          width="206"
          height="165"
          title="upwardItch"
        >
          <a href="https://talluff.itch.io/upward">Upward by Talluff</a>
        </iframe>
      ),
      gameImages: [
        {
          src: "https://img.itch.zone/aW1hZ2UvNzkyOTYxLzQ0NDM0ODEucG5n/original/mCV2aY.png",
          alt: "hi",
        },
        {
          src: "https://img.itch.zone/aW1hZ2UvNzkyOTYxLzQ0NDM0NzgucG5n/original/qAcW1Q.png",
          alt: "hi",
        },
        {
          src: "https://img.itch.zone/aW1hZ2UvNzkyOTYxLzQ0NDM0ODIucG5n/original/jPhsVF.png",
          alt: "hi",
        },
        {
          src: "https://img.itch.zone/aW1hZ2UvNzkyOTYxLzQ0NDM0NzkucG5n/original/PnyhP5.png",
          alt: "hey",
        },
      ],
      devDiary:
        "Quis irure sint qui excepteur. Aliquip aute eiusmod dolor amet nulla eu non exercitation occaecat mollit proident. Incididunt aliquip labore et consequat ad elit anim laborum. Voluptate minim ullamco duis occaecat ex elit eiusmod sint ullamco voluptate Lorem.",
      processImages: [
        { src: "", alt: "", description: "" },
        { src: "", alt: "", description: "" },
      ],
    },
  ];

  let addInNewLines = (title, description) => {
    let spl = description.split("\n");
    return spl.map((text, index) => {
      if (text === " ") return <br key={title + index} />;
      return <div key={title + index}>{text}</div>;
    });
  };

  let calcVideoWidth = () => {
    let windWidth = document.documentElement.clientWidth;
    if (windWidth < 720) {
      return windWidth * 0.9;
    } else if (windWidth < 1025) {
      return 560;
    } else if (windWidth < 1225) {
      return 480;
    } else if (windWidth < 1600) {
      return 560;
    } else {
      return 720;
    }
  };

  return (
    <SectionContent selected={selected === "Games"}>
      <SectionContentTitle selected={selected === "Games"}>
        Games
      </SectionContentTitle>
      {gamesList.map((gameObj, index) => {
        return (
          <div
            style={{
              padding: "4rem 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <GameTitle id={gameObj.title}>{gameObj.title}</GameTitle>
            <GameSubHeader>{gameObj.subHeader}</GameSubHeader>
            <GameSection key={gameObj.title}>
              <GameLeftSection selected={selected === "Games"}>
                <GameDescItch>
                  <GameDescription>
                    {addInNewLines(gameObj.title, gameObj.description)}
                  </GameDescription>
                  <GameItchCard bigDisplay={true}>
                    {gameObj.itchIframe}
                  </GameItchCard>
                </GameDescItch>
              </GameLeftSection>
              <GameRightSection selected={selected === "Games"}>
                <GameVideo
                  width={calcVideoWidth()}
                  height={calcVideoWidth() * 0.56}
                  src={gameObj.videoLink}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></GameVideo>
                <GameScreenshotsSection width={calcVideoWidth()}>
                  {gameObj.gameImages.map((imageObj, imageIndex) => {
                    return (
                      <GameScreenshot
                        key={`${imageObj.src}${imageIndex}`}
                        source={imageObj.src}
                        src={imageObj.src}
                        width={calcVideoWidth() / 4.1}
                        onClick={() =>
                          openLightbox(gameObj.gameImages, imageIndex)
                        }
                      />
                    );
                  })}
                </GameScreenshotsSection>
                <GameItchCard bigDisplay={false}>
                  {gameObj.itchIframe}
                </GameItchCard>
              </GameRightSection>
            </GameSection>
          </div>
        );
      })}
      <Footer />
    </SectionContent>
  );
}

export default Games;
